import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS } from '@contentful/rich-text-types';

const Footnote = ({ children }) => <sup>{children}</sup>;

const options = {
  renderMark: {
    [MARKS.CODE]: text => <Footnote>{text}</Footnote>,
  },
};

const renderRichText = content => documentToReactComponents(content, options);

export default renderRichText;
